import React from 'react';
import { Select } from 'antd';
import ReactMarkdown from 'react-markdown';
import { RenderingProps } from '../TicketOptions';
import '../MultipleChoice.css';

const SelectRendering = (props: RenderingProps) => (
  <>
    <div className="multipleChoiceTitle">{props.option}</div>
    <Select
      className="option-select-container"
      placeholder={props.option}
      onChange={(event) => {
        props.getRadioValue(
          props.option,
          props.currentStep,
          JSON.parse(event).name
        );
        props.getOption(JSON.parse(event), props.currentStep + 1);
        props.getOptionDesc(JSON.parse(event).description, props.currentStep);
      }}
      value={
        props.radioValue[props.currentStep]
          ? props.radioValue[props.currentStep][props.option]
          : null
      }
    >
      {props.currentOption[props.option].map((optionValue, i) => (
        <Select.Option key={i} value={JSON.stringify(optionValue)}>
          {optionValue.name}
        </Select.Option>
      ))}
    </Select>
    {props.optionArray && (
      <div className="description">
        <ReactMarkdown>{props.optionArray[props.currentStep]}</ReactMarkdown>
      </div>
    )}
  </>
);

export default SelectRendering;
