import React, { useCallback, useEffect, useState } from 'react';
import OptionListItem from './OptionListItem';
import { Spin } from 'antd';
import { OptionsChildProps } from '../MultipleChoice/TicketOptions';
import SessionOptionModel from '../../Models/SessionOptionModel';
import OptionRenderingValuesModel from '../../Models/OptionRenderingValuesModel';
import './Options.css';

interface OptionsListProps extends OptionsChildProps {
  list: OptionRenderingValuesModel[];
  loading: boolean;
  loadedSessionOptions: {
    options: SessionOptionModel[];
    productId: number;
    sessionId: number;
  }[];
}

const OptionsList = (props: OptionsListProps) => {
  const [sessionOptionsList, setSessionOptionsList] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const getOption = useCallback(
    (sessionOptions) => {
      let currentItem = sessionOptions.find(
        (item) =>
          item.sessionId === props.session.id &&
          item.productId === props.product.id
      );

      if (currentItem) {
        setSessionOptionsList(currentItem.options);
        setShowOptions(true);
      }
    },
    [props.product?.id, props.session?.id]
  );

  useEffect(() => {
    if (props.loadedSessionOptions) {
      getOption(props.loadedSessionOptions);
    } else {
      setShowOptions(true);
    }
  }, [getOption, props.loadedSessionOptions, props.selectedSessions]);

  useEffect(() => {
    if (props.selectedSessions) {
      getOption(props.loadedSessionOptions);
    }
  }, [getOption, props.loadedSessionOptions, props.selectedSessions]);

  return (
    <Spin spinning={sessionOptionsList.length === 0 && props.loading}>
      {showOptions &&
        props.list.map((listItem, index) => (
          <OptionListItem
            key={index}
            item={listItem}
            currentOptionList={sessionOptionsList}
            isDeletable={props.isDeletable}
            session={props.session}
            product={props.product}
            handleChangeQtySession={props.handleChangeQtySession}
            handleChangeQtyOption={props.handleChangeQtyOption}
            selectedOptions={props.selectedOptions}
            selectedSessions={props.selectedSessions}
          />
        ))}
    </Spin>
  );
};

export default OptionsList;
