import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { ticketsConstants } from '../constants/tickets.constants';
import {
  getTicketsFailure,
  getTicketsSuccess,
  getTicketDetailsSuccess,
  getTicketDetailsFailure,
} from '../actions/tickets.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getTicketsFetch(arg) {
  const url = shopConfig.router.productTicketListUrl;
  return await get(getUrl(url, arg.options));
}

export async function getTicketDetailsFetch(options) {
  const url = shopConfig.router.productDetailUrl;
  return await get(getUrl(url, options));
}

export function* fetchGetTickets(params) {
  try {
    const tickets = yield call(getTicketsFetch, { options: params.options });
    yield put(getTicketsSuccess(tickets));
  } catch (error) {
    yield put(getTicketsFailure(error));
  }
}

export function* fetchGetTicketDetails(params) {
  try {
    const ticket = yield call(getTicketDetailsFetch, { id: params.options });
    yield put(getTicketDetailsSuccess(ticket));
  } catch (error) {
    yield put(getTicketDetailsFailure(error));
  }
}

export function* getTickets() {
  yield takeLatest(ticketsConstants.GET_TICKETS_REQUEST, fetchGetTickets);
}

export function* getTicketDetails() {
  yield takeLatest(
    ticketsConstants.GET_TICKET_DETAILS_REQUEST,
    fetchGetTicketDetails
  );
}

export default function* ticketsSaga() {
  yield all([fork(getTickets), fork(getTicketDetails)]);
}
