import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putBasketItemRequest } from '../../../redux/actions/basket.actions';
import { DeleteOutlined } from '@ant-design/icons';
import { dispatchRemoveToCart } from '../../../services/tracking';
import { shopConfig } from '../../../Globals/ShopConfig';
import SimplePrice from '../../Price/SimplePrice';
import { getLocalTranslation } from '../../../services/getTranslation';
import dayjs from 'dayjs';
import Translator from '../../../services/translator';
import '../Basket.css';

class BasketAccomItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      occupant: [],
      assembly: null,
      locale: shopConfig.locale.locale,
      currency: shopConfig.locale.currencyCode,
    };
    this.newBasketItem = {
      itemId: null,
      quantity: null,
      project: shopConfig.project.name,
      pos: shopConfig.pos.slug,
    };
  }

  componentDidMount() {
    const { basketItem } = this.props;
    this.newBasketItem.itemId = this.props.basketItem.id;
    this.newBasketItem.quantity = this.props.basketItem.quantity;

    let newOccupant = [];
    let currentAssembly = null;

    if (basketItem.reservation.reservationOccupantTypes) {
      basketItem.reservation.reservationOccupantTypes.forEach(
        (reservationOccupantType) => {
          newOccupant.push(
            reservationOccupantType.quantity +
              ' ' +
              reservationOccupantType.occupantType.name
          );
        }
      );
    }

    if (basketItem.reservation.accommodationAssembly) {
      let currentTrad = getLocalTranslation(
        basketItem.reservation.accommodationAssembly.translations,
        this.state.locale
      );

      currentAssembly = currentTrad.name;
    }

    this.setState({ occupant: newOccupant, assembly: currentAssembly });
  }

  handleDeleteItem(basketItem) {
    this.props.setLoadingBasket(true);
    this.newBasketItem.quantity = 0;
    this.newBasketItem.itemId = basketItem.id;
    this.props.updateBasket(this.newBasketItem);

    dispatchRemoveToCart(basketItem.name, basketItem.price);
  }

  render() {
    const { basketItem, editable } = this.props;
    const { occupant, assembly } = this.state;

    return (
      <div className="basket-item-container">
        {!basketItem.isLocked && (
          <div className="basket-item-side">
            {editable && (
              <DeleteOutlined
                className="delete-item-button"
                onClick={() => this.handleDeleteItem(this.props.basketItem)}
              />
            )}
            <div>
              <div className="basket-item-info">
                <div className="basket-name">
                  <span>{basketItem.name}</span>
                </div>
                <div>
                  <SimplePrice
                    price={basketItem.priceUnit}
                    currency={this.state.currency}
                  />
                </div>
              </div>

              <div className="basket-item-info">
                <ul>
                  {assembly && <li>{assembly}</li>}
                  {basketItem.reservation.buyer && (
                    <li>{basketItem.reservation.buyer.fullName}</li>
                  )}
                  {occupant.length !== 0 && <li>{occupant.join(', ')}</li>}
                  <li>
                    {Translator.trans('accommodation.dates.range', {
                      departure: dayjs(basketItem.reservation.dateIn).format(
                        'L'
                      ),
                      arrival: dayjs(basketItem.reservation.dateOut).format(
                        'L'
                      ),
                    })}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateBasket: (updatedItem) => dispatch(putBasketItemRequest(updatedItem)),
});

export default connect(null, mapDispatchToProps)(BasketAccomItem);
