import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { getTicketDetailsRequest } from '../../redux/actions/tickets.actions';
import Translator from '../../services/translator';
import RequiredItemModel from '../../Models/RequiredItemModel';
import './RequiredProducts.css';

interface RequiredProductsProps {
  classIcon: string;
  products: RequiredItemModel[];
}

const RequiredProducts = (props: RequiredProductsProps) => {
  const dispatch = useDispatch();

  const getTicketDetails = useCallback(
    (ticketId) => {
      dispatch(getTicketDetailsRequest(ticketId));
    },
    [dispatch]
  );

  const addRequiredProduct = (productId: number) => {
    if (productId) {
      const buttonAddProduct = document.getElementById(
        'add-product-' + productId
      );
      if (buttonAddProduct) {
        buttonAddProduct.click();
      } else {
        getTicketDetails(productId);
      }
    }
  };

  const renderTooltipBody = (products: RequiredItemModel[]) => {
    const productList = products.map((product) => {
      return (
        <li
          key={product.formuleId}
          className="add-required-product"
          onClick={() => addRequiredProduct(product.formuleId)}
        >
          {product.formuleName}
        </li>
      );
    });

    return (
      <div>
        <span>{Translator.trans('product.required')}</span>
        <ul>{productList}</ul>
      </div>
    );
  };

  return (
    <Tooltip
      title={() => renderTooltipBody(props.products)}
      placement="bottomLeft"
      zIndex={9999}
    >
      <WarningOutlined className={props.classIcon} />
    </Tooltip>
  );
};

export default RequiredProducts;
